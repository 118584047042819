//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LANGUAGE_OPTIONS } from '@/enum';
import InputHtmlModal from './InputHtmlModal.vue';
import RichContentModal from './RichContentModal.vue';

export default {
  components: { InputHtmlModal, RichContentModal },
  props: {
    code: { type: Object, default: () => ({}) },
    langOptions: { type: Array, default: () => [] },
    sound: {
      type: Object,
      default: () => ({
        lexicon: [],
        engine: 'standard'
      })
    }
  },

  data() {
    return {
      visibleInputHTMLModal: false,
      visibleRichContentModal: false
    };
  },

  computed: {
    codeLang() {
      return this.code.lang || '';
    },
    enumLangs() {
      return LANGUAGE_OPTIONS;
    },
    ragtData() {
      return {
        ...this.code.ragt,
        sound: this.sound
      };
    }
  },

  methods: {
    changeLang(value) {
      this.$emit('changeLang', { lang: value, id: this.code?.id });
    },
    changeTitle({ target }) {
      this.$emit('changeCodeTitle', { title: target.value, id: this.code?.id });
    },
    openCode() {
      this.$emit('openCode', this.code);
    },
    deleteCode() {
      this.$emit('deleteCode', this.code.id);
    },
    changeCodeContent({ target }) {
      this.$emit('changeCodeContent', { id: this.code.id, content: target.value });
    },
    confirmRevertPlainText() {
      this.$emit('confirmRevertPlainText', this.code);
    },
    previewCutPlainText() {
      this.$emit('previewCutPlainText', this.code.id);
    },
    submitInputHTMLModal(data) {
      this.visibleInputHTMLModal = false;
      this.$emit('openRagtEditor', { selectedCode: this.code, simplifiedHTML: data });
    }
  }
};
