export default {
  supportedLangs: {
    ja: {
      label: 'Japanese',
      default: true
    },
    en: {
      label: 'English'
    },
    'zh-Hans': {
      label: 'Simplified Chinese'
    },
    'zh-Hant': {
      label: 'Traditional Chinese'
    },
    ko: {
      label: 'Korean'
    },
    fr: {
      label: 'French'
    },
    de: {
      label: 'German'
    },
    es: {
      label: 'Spanish'
    },
    it: {
      label: 'Italian'
    },
    pt: {
      label: 'Portuguese'
    },
    ru: {
      label: 'Russian'
    },
    th: {
      label: 'Thai'
    },
    vi: {
      label: 'Vietnamese'
    },
    id: {
      label: 'Indonesian'
    },
    ar: {
      label: 'Arabic'
    },
    ms: {
      label: 'Malay'
    },
    hi: {
      label: 'Hindi'
    },
    nl: {
      label: 'Dutch'
    }
  },
}
