//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BaseForm } from '@/components/push';
import { mapGetters } from 'vuex';
import { MESSAGE_STATUS, BASE_ATTACHMENT_OPTIONS } from '@/enum';
import { validateURL } from '@/utils/util';

import { QRUploader } from './contents';
import AttachmentOption from './contents/AttachmentOption.vue';
import { messageMixin } from './mixin';

export default {
  props: {
    pushMessage: { type: Object }
  },
  components: {
    BaseForm,
    QRUploader,
    AttachmentOption
  },
  mixins: [messageMixin],
  computed: {
    ...mapGetters('message', ['supportedLangs', 'defaultLang', 'langByCode']),
    ...mapGetters(['currentLanguage']),
    ...mapGetters('user', ['userInfo']),
    localizeSupported() {
      return this.supportedLangs.map(item => {
        let newItem = { ...item };
        newItem.label = this.$t(item.label);
        return newItem;
      });
    },
    selectedLangs() {
      const data = this.pushMessage.localized_messages.map(localize => {
        return localize.lang;
      });
      return data;
    },
    localizes() {
      const data = this.pushMessage.localized_messages.map(localize => {
        const lang = this.langByCode(localize.lang);
        return {
          lang: {
            value: localize.lang,
            ...lang
          },
          title: localize.title,
          content: localize.content
        };
      });
      return data;
    },
    statusColor() {
      switch (this.pushMessage.status) {
        case 'SENT':
          return 'green';
        case 'WAITING':
          return 'orange';
        case 'REJECTED':
          return 'red';
        default:
          break;
      }
      return null;
    },
    status() {
      return MESSAGE_STATUS[this.pushMessage.status];
    },
    formItemLayout() {
      return {
        labelCol: { lg: { span: 4 }, md: { span: 4 }, sm: { span: 24 } },
        wrapperCol: { lg: { span: 20 }, md: { span: 20 }, sm: { span: 24 } }
      };
    },
    formItemTail() {
      return {
        wrapperCol: { lg: { offset: 4, span: 20 }, md: { offset: 4, span: 20 }, sm: { span: 24 } }
      };
    },
    attachmentOptions() {
      const options = BASE_ATTACHMENT_OPTIONS.map(option => {
        const isExist = this.attachments.some(attach => option.value === attach.type);
        return {
          ...option,
          label: this.$t(option.label),
          disable: isExist
        };
      });
      return options;
    },
    codeId() {
      return this.pushMessage?.code_id || null;
    },
    pageId() {
      return this.pushMessage?.code?.project_id || null;
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),

      activeKey: 'ja',
      newTabIndex: 0,

      visible: false,
      confirmLoading: false,

      loading: true,

      tmpLangs: ['ja'],
      errors: {},

      attachments: [],
      qrcode: {},

      index: 0,
      isSubmited: false
    };
  },
  watch: {
    pushMessage: {
      handler() {
        this.attachments =
          this.pushMessage?.attachment?.map(item => ({ ...item, id: this.index++ })) || [];
        this.qrcode = this.pushMessage?.qrcode ?? {};

        this.activeKey = this.pushMessage?.localized_messages[0]?.lang;
      },
      immediate: true
    }
  },
  methods: {
    async redirectToCodeList() {
      const page = await this.$s.api.page.findOneByCompanyId(this.userInfo.company_id, this.pageId);
      this.$router.push({
        name: 'voice-code-detail',
        params: { projectId: page.campaign_id, pageId: this.pageId, codeId: this.codeId }
      });
    },
    closable(localize) {
      if (localize.lang.default) {
        return false;
      }
      return this.canSave;
    },
    isError(code) {
      return !!this.errors[code];
    },

    async validate(type) {
      this.isSubmited = true;
      try {
        // clean this.errors
        this.errors = {};
        this.$notification.destroy();
        let localizes;
        if (type === 'preview') {
          localizes = await this.validateLocalize(type);
        } else {
          localizes = await Promise.all(this.validateLocalize(type));
        }
        const newAttachments = await this.validateAttachment();
        return {
          localized_messages: localizes,
          attachment: newAttachments,
          ...(this.qrcode?.link && { qrcode: this.qrcode })
        };
      } catch (err) {
        const error = {};
        this.$refs.base.forEach(refComp => {
          const fieldsError = refComp.form.getFieldsError();
          const isError = Object.keys(fieldsError).some(key => {
            return !!fieldsError[key];
          });
          if (isError) {
            error[refComp.$vnode.key] = fieldsError;
          }
        });
        this.errors = { ...error };
        throw err;
      }
    },

    validateLocalize(type) {
      if (type === 'preview') {
        const index = this.localizes.findIndex(item => item.lang.value === this.activeKey);
        return new Promise((resolve, reject) => {
          return this.$refs.base[index].form.validateFieldsAndScroll((err, values) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(values);
          });
        });
      }
      return this.$refs.base.map(refComp => {
        return new Promise((resolve, reject) => {
          return refComp.form.validateFieldsAndScroll((err, values) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(values);
          });
        });
      });
    },

    validateAttachment() {
      return new Promise((resolve, reject) => {
        const errors = this.attachments
          .filter(
            item => (item.type && !item.url) || (item.type === 'url' && !validateURL(item.url))
          )
          .map(item => item.type);
        const newAttachments = this.attachments.filter(item => item.type && item.url);
        return errors.length > 0
          ? reject({ type: 'attachments', errors })
          : resolve(newAttachments);
      });
    },

    onEdit(targetKey, action) {
      this[action](targetKey);
    },

    onQrUrlChange(url) {
      this.qrcode.link = url;
    },

    onQrRemove() {
      this.qrcode = {};
    },

    onChange(values) {
      this.tmpLangs = values;
    },

    add(code) {
      const panes = this.pushMessage.localized_messages;
      panes.push({
        lang: code
      });
      this.pushMessage.localized_messages = [...panes];
      this.activeKey = code;
    },
    remove(targetKey) {
      const panes = this.pushMessage.localized_messages.filter(lang => lang.lang !== targetKey);
      this.pushMessage.localized_messages = [...panes];
      this.activeKey = panes[panes.length - 1].lang;
    },

    handleOk() {
      this.confirmLoading = true;
      if (this.tmpLangs) {
        // TODO, this logic need to improve
        const addList = [];
        this.tmpLangs.forEach(element => {
          const isExist = this.pushMessage.localized_messages.some(c => element === c.lang);
          if (!isExist) {
            addList.push(element);
          }
        });
        const removeList = [];
        this.pushMessage.localized_messages.forEach(c => {
          const isExist = this.tmpLangs.some(element => element === c.lang);
          if (!isExist) {
            removeList.push(c.lang);
          }
        });
        addList.forEach(lang => this.add(lang));
        removeList.forEach(lang => this.remove(lang));
      }
      this.visible = false;
      this.confirmLoading = false;
    },

    showSelectLang() {
      this.tmpLangs = ['ja'];
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
    handleSubmit(e) {
      e.preventDefault();
    },
    addAttachment() {
      this.attachments = [...this.attachments, { id: this.index++ }];
    },
    removeAttachment(attachment) {
      const newAttachments = this.attachments.filter(item => item.type !== attachment.type);
      this.attachments = [...newAttachments];
    },
    changeAttachment(value) {
      const newAttachments = this.attachments.map(item => (item.id === value.id ? value : item));
      this.attachments = [...newAttachments];
    }
  }
};
