var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.page
    ? _c(
        "section",
        { staticClass: "voice-code" },
        [
          _c(
            "page-header",
            {
              staticClass: "mb-xs",
              attrs: {
                "show-back-icon": "",
                title: _vm.currentProject.name + " / " + _vm.page.name,
                "show-status": "",
                status: _vm.page.ispublic
              },
              on: {
                back: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [
              _c(
                "template",
                { slot: "extra" },
                [
                  _c(
                    "a-dropdown",
                    [
                      _c(
                        "a-menu",
                        { attrs: { slot: "overlay" }, slot: "overlay" },
                        [
                          _c(
                            "a-menu-item",
                            { key: "deleteCode" },
                            [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    placement: "left",
                                    title: _vm.$t(
                                      "Are you sure to delete this code"
                                    ),
                                    "ok-text": _vm.$t("Yes"),
                                    "cancel-text": _vm.$t("No")
                                  },
                                  on: {
                                    confirm: function() {
                                      return _vm.deleteCode()
                                    }
                                  }
                                },
                                [
                                  _c("a-icon", { attrs: { type: "delete" } }),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            {
                              key: "handleCopyCodeOpening",
                              on: { click: _vm.handleCopyCodeOpening }
                            },
                            [
                              _c("a-icon", { attrs: { type: "copy" } }),
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t("Copy")) + " ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            { key: "handleDuplicateCode" },
                            [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    placement: "bottomRight",
                                    title: _vm.$t(
                                      "Would you like to duplicate this code？"
                                    ),
                                    "ok-text": _vm.$t("Yes"),
                                    "cancel-text": _vm.$t("No")
                                  },
                                  on: { confirm: _vm.duplicateCode }
                                },
                                [
                                  _c("a-icon", { attrs: { type: "copy" } }),
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("Duplicate")) + " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            {
                              key: "handleMoveCodeOpening",
                              on: { click: _vm.handleMoveCodeOpening }
                            },
                            [
                              _c("a-icon", { attrs: { type: "drag" } }),
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t("Move")) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        [
                          _vm._v(_vm._s(_vm.$t("Actions"))),
                          _c("a-icon", { attrs: { type: "down" } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loading, tip: _vm.$t("Loading...") } },
            [
              _vm.codeDetail && _vm.codeDetail.error
                ? _c("a-alert", {
                    staticClass: "my-xs",
                    attrs: {
                      type: "error",
                      message: _vm.codeDetail.error,
                      "show-icon": ""
                    }
                  })
                : _vm._e(),
              _c("basic-info", {
                attrs: {
                  "page-id": +_vm.pageId,
                  "code-id": _vm.codeDetail && _vm.codeDetail.id,
                  title: _vm.title,
                  "created-date": _vm.createdDate,
                  size: _vm.size,
                  "ec-level": _vm.ecLevel,
                  categoryId: _vm.categoryId,
                  previewQRCode:
                    _vm.codeDetail && _vm.codeDetail.preview_qrcode,
                  versions: _vm.versions,
                  "color-status": _vm.colorStatus,
                  "code-status": _vm.codeStatus
                },
                on: {
                  changeTitle: _vm.changeTitle,
                  changeCreatedDate: _vm.changeCreatedDate,
                  changeCodeSize: _vm.changeCodeSize,
                  changeErrorEcLevel: _vm.changeErrorEcLevel,
                  changeCategory: _vm.changeCategory
                }
              }),
              _c("advanced-info", {
                attrs: {
                  thumbnail: _vm.thumbnail,
                  linkURL: _vm.linkURL,
                  janCodes: _vm.janCodes,
                  tags: _vm.tags
                },
                on: {
                  changeOriginalLink: _vm.changeOriginalLink,
                  removeJanCode: _vm.removeJanCode,
                  addJanCode: _vm.addJanCode,
                  changeThumbnail: _vm.changeThumbnail,
                  changeTags: _vm.changeTags
                }
              }),
              _c("dictionary-info", {
                attrs: {
                  codeDictionary: _vm.dictionary,
                  projectDictionary: _vm.projectDictionary,
                  pageDictionary: _vm.pageDictionary
                },
                on: { changeDictionary: _vm.changeDictionary }
              }),
              _c("a-list", {
                attrs: {
                  "data-source": _vm.codes,
                  grid: { gutter: 16, xs: 1, sm: 1, md: 1, lg: 1 },
                  locale: {
                    emptyText: _vm.$t("No data")
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "renderItem",
                      fn: function(item) {
                        return _c(
                          "a-list-item",
                          {},
                          [
                            item.ragt
                              ? _c("rich-code-item", {
                                  attrs: {
                                    code: item,
                                    "lang-options": _vm.noUseLangs,
                                    sound: _vm.soundOptions
                                  },
                                  on: {
                                    changeLang: _vm.changeCodeItemLang,
                                    changeCodeTitle: _vm.changeCodeItemTitle,
                                    changeCodeContent:
                                      _vm.changeCodeItemContent,
                                    deleteCode: _vm.deleteCodeItem,
                                    openCode: _vm.openRagtCodeItem,
                                    confirmRevertPlainText:
                                      _vm.confirmRevertPlainText,
                                    previewCutPlainText:
                                      _vm.previewCutPlainTextByCodeId,
                                    openRagtEditor: _vm.openRagtEditor
                                  }
                                })
                              : _c("plain-code-item", {
                                  attrs: {
                                    code: item,
                                    "lang-options": _vm.noUseLangs,
                                    engine:
                                      (_vm.codeDetail.speech &&
                                        _vm.codeDetail.speech.engine) ||
                                      "standard",
                                    lexicon: _vm.page.lexicon || [],
                                    dictionary:
                                      (_vm.dictionary.length > 0 &&
                                        _vm.dictionary) ||
                                      (_vm.pageDictionary.length > 0 &&
                                        _vm.pageDictionary) ||
                                      (_vm.projectDictionary.length > 0 &&
                                        _vm.projectDictionary) ||
                                      [],
                                    status: _vm.status
                                  },
                                  on: {
                                    changeLang: _vm.changeCodeItemLang,
                                    changeCodeTitle: _vm.changeCodeItemTitle,
                                    changeCodeContent:
                                      _vm.changeCodeItemContent,
                                    changeSSMLContent: _vm.changeSSMLContent,
                                    enableSSML: _vm.enableSSML,
                                    deleteCode: _vm.deleteCodeItem,
                                    onPlaySpeech: _vm.handlePlaySpeech,
                                    onPauseSpeech: _vm.handlePauseSpeech,
                                    catchSSMLError: _vm.catchSSMLError,
                                    previewCodeContent:
                                      _vm.previewPlainCodeContent
                                  }
                                })
                          ],
                          1
                        )
                      }
                    }
                  ],
                  null,
                  false,
                  711929705
                )
              }),
              _c(
                "a-button",
                { staticClass: "mt-xs mb-md", on: { click: _vm.addCode } },
                [_vm._v(" " + _vm._s(_vm.$t("Add Language")) + " ")]
              ),
              _c(
                "a-row",
                {
                  attrs: { gutter: [16, 16], type: "flex", justify: "center" }
                },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 8, md: 6, lg: 6 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            icon: _vm.ispublic ? "lock" : "unlock",
                            loading: _vm.publicLoading,
                            disabled: _vm.deActiveButton
                          },
                          on: {
                            click: function($event) {
                              _vm.ispublic
                                ? _vm.handlePrivateCode()
                                : _vm.handlePublicCode()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.ispublic ? "Private" : "Public")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 24, sm: 8, md: 6, lg: 6 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "dashed",
                            icon: "eye",
                            disabled: _vm.deActiveButton
                          },
                          on: { click: _vm.previewQRCode }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Show demo")) + " ")]
                      )
                    ],
                    1
                  ),
                  _vm.messages && _vm.messages.length > 0
                    ? _c(
                        "a-col",
                        { attrs: { xs: 24, sm: 8, md: 6, lg: 6 } },
                        [
                          _c(
                            "a-dropdown",
                            [
                              _c(
                                "a-menu",
                                { attrs: { slot: "overlay" }, slot: "overlay" },
                                _vm._l(_vm.messages, function(item) {
                                  return _c(
                                    "a-menu-item",
                                    { key: item.id },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "message-detail",
                                              params: { id: item.id }
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "max-width": "300px",
                                                overflow: "hidden",
                                                "white-space": "nowrap",
                                                "text-overflow": "ellipsis"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.all_title) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "max-width": "95%",
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        "text-overflow": "ellipsis"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Link to messages")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("a-icon", { attrs: { type: "down" } })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isDraftStatus
                    ? [
                        _c(
                          "a-col",
                          { attrs: { xs: 24, sm: 8, md: 6, lg: 6 } },
                          [
                            _c(
                              "a-button",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "primary",
                                  ghost: "",
                                  icon: "save",
                                  loading: _vm.loading,
                                  disabled: _vm.deActiveButton
                                },
                                on: { click: _vm.saveDraft }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Save as draft")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { xs: 24, sm: 8, md: 6, lg: 6 } },
                          [
                            _c(
                              "a-popconfirm",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placement: "top",
                                  title: _vm.$t(
                                    "Are you sure to generate code?"
                                  ),
                                  "ok-text": _vm.$t("Yes"),
                                  "cancel-text": _vm.$t("No"),
                                  disabled: _vm.deActiveButton
                                },
                                on: { confirm: _vm.publishCode }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    class: {
                                      blink: true,
                                      blinking: _vm.isDefaultDataChanged
                                    },
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      loading: _vm.loading,
                                      disabled: _vm.deActiveButton
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "15px",
                                        height: "15px",
                                        "margin-right": "5px"
                                      },
                                      attrs: {
                                        src: require("@/assets/logo.png")
                                      }
                                    }),
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("Generate")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "a-col",
                          { attrs: { xs: 24, sm: 8, md: 6, lg: 6 } },
                          [
                            _c(
                              "a-button",
                              {
                                staticStyle: {
                                  width: "100%",
                                  display: "flex",
                                  "justify-content": "center",
                                  "align-items": "center"
                                },
                                on: { click: _vm.handleComposeNewMessage }
                              },
                              [
                                _c("a-icon", {
                                  staticClass: "mr-xs",
                                  attrs: { type: "notification" }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "white-space": "nowrap",
                                      overflow: "hidden",
                                      "text-overflow": "ellipsis"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("Compose new message")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { xs: 24, sm: 8, md: 6, lg: 6 } },
                          [
                            _c(
                              "a-popconfirm",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placement: "top",
                                  title: _vm.$t(
                                    "Are you sure to re-generate code ?"
                                  ),
                                  "ok-text": _vm.$t("Yes"),
                                  "cancel-text": _vm.$t("No"),
                                  disabled: _vm.deActiveButton
                                },
                                on: { confirm: _vm.regenerateCode }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    class: {
                                      blink: true,
                                      blinking: _vm.isDefaultDataChanged
                                    },
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      loading: _vm.loading,
                                      disabled: _vm.deActiveButton
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "15px",
                                        height: "15px",
                                        "margin-right": "5px"
                                      },
                                      attrs: {
                                        src: require("@/assets/logo.png")
                                      }
                                    }),
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("Re-Generate")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                ],
                2
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                "dialog-style": { top: "10px" },
                title: _vm.$t("Preview")
              },
              model: {
                value: _vm.previewCutPlainTextVisible,
                callback: function($$v) {
                  _vm.previewCutPlainTextVisible = $$v
                },
                expression: "previewCutPlainTextVisible"
              }
            },
            [
              _c("div", {
                staticClass: "content-group",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.nl2br(_vm.contentPreview)
                  )
                }
              }),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: {
                        click: function() {
                          return (_vm.previewCutPlainTextVisible = false)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c("preview-qrcode", { ref: "previewQRRef" }),
          _vm.choosePageModalVisible
            ? _c("choose-page-modal", {
                attrs: {
                  "action-type": _vm.pageChoosingActionType,
                  visible: _vm.choosePageModalVisible,
                  projects: _vm.projectsAndPages
                },
                on: {
                  copy: _vm.handleCopyCodes,
                  move: _vm.handleMoveCodes,
                  cancel: function($event) {
                    _vm.choosePageModalVisible = false
                  }
                }
              })
            : _vm._e(),
          _c(
            "a-modal",
            {
              staticStyle: { top: "0" },
              attrs: {
                width: "100%",
                wrapClassName: "full-modal",
                visible:
                  _vm.visibleRagtEditor &&
                  _vm.selectedCode &&
                  Object.keys(_vm.selectedCode).length > 0,
                "dialog-style": { top: "0" },
                bodyStyle: { padding: "0" },
                footer: false,
                forceRender: true,
                closable: false
              }
            },
            [
              _vm.visibleRagtEditor && _vm.selectedCode && _vm.selectedCode.ragt
                ? _c("wc-ragt-editor", {
                    attrs: { id: _vm.ragtKey, "ragt-pros": _vm.ragtPros },
                    on: {
                      submit: _vm.submitRagtEditor,
                      finish: _vm.cancelRagtEditorModal,
                      close: _vm.cancelRagtEditorModal
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: { title: _vm.$t("Preview") },
              model: {
                value: _vm.plainCodeContentModalVisible,
                callback: function($$v) {
                  _vm.plainCodeContentModalVisible = $$v
                },
                expression: "plainCodeContentModalVisible"
              }
            },
            [
              _c("div", {
                staticClass: "content-group",
                domProps: { innerHTML: _vm._s(_vm.plainCodeContentPreview) }
              }),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      on: {
                        click: function() {
                          return (_vm.plainCodeContentModalVisible = false)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }