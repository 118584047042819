//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoSleep from 'nosleep.js';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { LANGUAGE_OPTIONS, UV_TO_RAGT_LANG } from '@/enum';
import CodeSize from '@/components/spec/code-compose/child/CodeSize.vue';
import PreviewQRCode from '@/components/spec/code-compose/child/manual/modals/PreviewQRCode';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import {
  dbLength,
  symSizeLength,
  buildCodeContent,
  subStringSpecialLangCase
} from '@/components/_util/encode';
import { AdvancedInfo, ImportFile, BasicInfo } from './components';
import { deepDiffMapper } from '@/utils/diff-objects';

const RAGT_SCRIPT_ID = 'ragt_editor_id';

export default {
  components: {
    PageHeader,
    PreviewQrcode: PreviewQRCode,
    CodeSize,
    AdvancedInfo,
    ImportFile,
    BasicInfo,
    RichCodeItem: () => import('./components/RichCodeItem.vue')
  },
  data() {
    return {
      loading: false,
      title: '',
      size: '2',
      ecLevel: '1',
      linkURL: '',
      codes: [
        {
          id: 1,
          lang: 'jpn',
          text: '',
          title: '',
          isPlaySpeech: false,
          contentOverLimit: false,
          ragt: {
            id: '',
            html: ''
          },
          ssml: {
            text: '',
            is_enable: false
          }
        }
      ],
      audio: null,
      noSleep: new NoSleep(),
      categoryId: null,
      contentPreview: '',
      previewCodeContentVisible: false,
      thumbnail: {
        url: '',
        alt: ''
      },
      janCodes: [
        {
          id: 0,
          value: ''
        }
      ],
      tags: [],
      uploadLoading: false,
      activeTab: 'manual',
      createdDate: moment(),
      visibleRagtEditor: false,
      selectedCode: null,
      ragtKey: `ragt-compose-${Math.random(10)}`,
      visibleInputHTMLModal: false,
      simplifiedHTML: '',
      defaultCode: null,
      pollyEngine: 'standard',

      isEditedFistCodeName: false
    };
  },
  computed: {
    ...mapGetters('page', ['pageById']),
    ...mapGetters('project', ['projectById']),
    ...mapGetters(['currentLanguage']),
    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    page() {
      return this.pageById(this.pageId);
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    deActiveButton() {
      return (
        this.codes.length < 1 ||
        !this.title ||
        (this.thumbnail?.url && !this.thumbnail?.alt) ||
        this.codes.some(({ ragt, lang }) => !ragt.id || !lang)
      );
    },
    validateTitle() {
      return this.activeTab === 'manual' && this.title === '' ? 'error' : 'success';
    },
    validateThumbnailALT() {
      const { url, alt } = this.thumbnail;
      return url && !alt ? 'error' : 'success';
    },
    enumLangs() {
      return LANGUAGE_OPTIONS;
    },
    usedLangs() {
      return this.codes.reduce((acc, item) => acc.concat(item.lang), []) || [];
    },
    noUseLangs() {
      return Object.keys(this.enumLangs).filter(langKey => !this.usedLangs?.includes(langKey));
    },
    soundOptions() {
      return {
        lexicon: this.page.lexicon || [],
        engine: this.pollyEngine || 'standard'
      };
    },
    ragtPros() {
      return JSON.stringify({
        skipSteps: JSON.stringify([0, 1]),
        locale: this.currentLanguage,
        lang: UV_TO_RAGT_LANG[this.selectedCode?.lang || 'eng'],
        ragtId: this.selectedCode?.ragt?.id || '',
        ragtKey: this.ragtKey,
        html: this.simplifiedHTML,
        ragtClientId: process.env.VUE_APP_RAGT_CLIENT_ID,
        sound: this.soundOptions || { lexicon: [], engine: 'standard' },
        showCloseBtn: true
      });
    },
    code() {
      return {
        title: this.title,
        size: this.size,
        ecLevel: this.ecLevel,
        linkURL: this.linkURL,
        codes: this.codes,
        categoryId: this.categoryId,
        thumbnail: this.thumbnail,
        janCodes: this.janCodes,
        tags: this.tags,
        createdDate:
          typeof this.createdDate == 'string' ? this.createdDate : this.createdDate.toISOString()
      };
    },
    isDefaultDataChanged() {
      const result = deepDiffMapper.isDiff(this.defaultCode, this.code);
      return !this.deActiveButton && result;
    }
  },
  beforeRouteLeave(to, from, next) {
    const that = this;
    if (this.$ls.get('confirm-leave')) {
      this.$ls.remove('confirm-leave');
      return next();
    }
    if (to.name !== 'code-detail') {
      return this.$confirm({
        title: that.$t('Are you sure to leave this code detail?'),
        content: that.$t('The changed content will not be save.'),
        cancelText: that.$t('No, cancel'),
        okText: that.$t('Ok'),
        okType: 'primary',
        onOk() {
          next();
        },
        onCancel() { },
        class: 'test',
        width: 433
      });
    }
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
    }
    this.noSleep.disable();
    this.destroyRagtEditor();
  },
  async created() {
    this.loadRagtEditor();
    this.getPageById({ projectId: this.projectId, pageId: this.pageId });
    this.defaultCode = JSON.parse(
      JSON.stringify({
        title: this.title,
        size: this.size,
        ecLevel: this.ecLevel,
        linkURL: this.linkURL,
        codes: this.codes,
        categoryId: this.categoryId,
        thumbnail: this.thumbnail,
        janCodes: this.janCodes,
        tags: this.tags,
        createdDate:
          typeof this.createdDate == 'string' ? this.createdDate : this.createdDate.toISOString()
      })
    );

    const speechEngine = await this.$s.api.remoteConfig.getRemoteConfig('pollyEngine');
    if (speechEngine?.str_val) {
      this.pollyEngine = speechEngine.str_val;
    }
  },
  methods: {
    ...mapActions('page', ['getPageById']),

    // TODO: Basic info's Event
    changeTitle(value) {
      this.title = value;

      if (!this.isEditedFistCodeName || !this.codes[0].title) {
        this.codes[0].title = this.title
      }
    },
    changeCreatedDate(value) {
      this.createdDate = value;
    },
    changeCodeSize(value) {
      this.size = value;
      // check limit content of all codes
      this.checkAndShowIfContentLimitCodes();
    },
    changeErrorEcLevel(value) {
      this.ecLevel = value;
    },
    changeCategory(value) {
      this.categoryId = value;
    },

    // TODO: Advanced info' event
    changeOriginalLink({ target }) {
      this.linkURL = target.value;
      // check limit content of all codes
      this.checkAndShowIfContentLimitCodes();
    },
    removeJanCode(janCodesRemoved) {
      this.janCodes = this.janCodes.reduce((arr, item) => {
        if (item.id !== janCodesRemoved.id) arr.push(item);
        return arr;
      }, []);
    },
    addJanCode() {
      const payload = {
        id: this.janCodes?.length || 0,
        value: ''
      };
      this.janCodes.push(payload);
    },
    changeThumbnail(newThumbnail) {
      this.thumbnail = { ...newThumbnail };
    },

    changeCodeItemTitle({ title, id }) {
      const codeIndex = this.codes.findIndex(item => item.id === id)
      if (!title) {
        this.isEditedFistCodeName = false
      } else if (codeIndex === 0 && !this.isEditedFistCodeName) { // Enable the isEditedFistCodeName flag
        this.isEditedFistCodeName = true
      }

      const codeItem = this.codes[codeIndex]
      codeItem.title = title;
    },
    changeCodeItemLang({ lang, id }) {
      const code = this.codes.find(code => code.id === id);
      code.lang = lang;
    },
    openCodeItem(item) {
      this.selectedCode = { ...item };
      this.visibleRagtEditor = true;
    },
    deleteCodeItem(id) {
      this.codes = [...this.codes.filter(item => item.id !== id)];
      // check limit content of all codes
      this.checkAndShowIfContentLimitCodes();
    },
    changeCodeItemContent({ id, content }) {
      const codeItem = this.codes.find(item => item.id === id);
      codeItem.text = content;
      codeItem.contentOverLimit = this.checkContentOverLimit(codeItem);
    },
    confirmRevertPlainText(code) {
      const currentCode = this.codes.find(item => item.id == code?.id);
      if (currentCode) {
        const self = this;
        this.$confirm({
          width: '500px',
          content: () => (
            <div>
              <div style="margin-bottom: 12px; font-weight: bold;">
                {this.$t('Do you want to revert the previous version?')}
              </div>
              <div
                class="preview-container"
                style="padding: 12px;"
                domPropsInnerHTML={this.$options.filters.nl2br(currentCode.preText)}
              />
            </div>
          ),
          onOk() {
            currentCode.text = currentCode.preText;
            currentCode.contentOverLimit = self.checkContentOverLimit(code);
          },
          okText: 'Revert',
          cancelText: this.$t('Cancel'),
          onCancel() {
            console.log('Close');
          }
        });
      }
    },
    previewCutPlainTextByCodeId(id) {
      const codeItem = this.codes.find(item => item.id === id);
      const MAX_LEN =
        (symSizeLength(this.size) - dbLength(this.linkURL)) / this.codes.length -
        subStringSpecialLangCase(codeItem.lang, this.size);

      this.contentPreview = buildCodeContent(codeItem.text, codeItem.lang, MAX_LEN);
      this.previewCodeContentVisible = true;
    },
    openRagtEditor({ selectedCode, simplifiedHTML }) {
      this.simplifiedHTML = simplifiedHTML;
      this.selectedCode = selectedCode;
      this.visibleRagtEditor = true;
    },

    loadRagtEditor() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = RAGT_SCRIPT_ID;
      script.src = `${process.env.VUE_APP_RAGT_EDITOR_URL}?v=${Math.random()}`;
      document.head.appendChild(script);
    },
    destroyRagtEditor() {
      document.head?.removeChild(document?.getElementById(RAGT_SCRIPT_ID));
    },
    cancelRagtEditorModal() {
      this.selectedCode = null;
      this.visibleRagtEditor = false;
      this.simplifiedHTML = '';
    },
    async submitRagtEditor(event) {
      const { ragtId, ragtJson, ragtHTML, resolve, reject } = event?.detail;
      try {
        if (!this.title && ragtJson?.metaOpt?.title) {
          this.title = ragtJson.metaOpt.title;
        }

        if (ragtJson) {
          const res = await this.$s.api.ragtEditor.InsertOrUpdateRagtJson({
            id: ragtId,
            ragtJson,
            html: ragtHTML
          });
          const code = this.codes.find(item => item.id == this.selectedCode?.id);
          if (code) {
            // code.lang = RAGT_TO_UV_LANG[ragtJson?.metaOpt?.lang || 'ja'];
            code.ragt = { id: res.id, html: ragtHTML };
            code.preText = code.text;
            code.text = res.text;
            code.contentOverLimit = this.checkContentOverLimit(code);
          }
          resolve({ id: res.id });
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    },
    addCode() {
      const id = this.codes.length + 1;
      const newItem = {
        id,
        lang: this.noUseLangs[0],
        text: '',
        title: '',
        ragt: { id: '', html: '' },
        isPlaySpeech: false,
        contentOverLimit: false
      };
      this.codes = [...this.codes, newItem];
      // check limit content of all codes
      this.checkAndShowIfContentLimitCodes();
    },
    standardizedCode({ status, title, category_id, codes }) {
      this.createdDate = moment(this.createdDate).add(1, 'seconds');
      return {
        // commons fields
        created: this.createdDate,
        sym_size: this.size,
        sym_ec_level: this.ecLevel,
        linkURL: this.linkURL,
        thumbnail: this.thumbnail,
        jan_codes: this.janCodes.filter(item => item.value).map(item => item.value) || [],
        tags: this.tags,
        // specific fields
        status: status,
        title: title,
        category_id,
        codes: codes
      };
    },
    async saveCodesList({ status, codes }) {
      try {
        this.$ls.set('confirm-leave', true);
        this.loading = true;
        for (let i = 0; i < codes.length; i++) {
          const { title, categoryId, id, lang, text } = codes[i];
          await this.$s.api.page.createCodes(this.pageId, {
            ...this.standardizedCode({
              status,
              title,
              category_id: categoryId,
              codes: [{ id, lang, text, title: title, ssml: { is_enable: false, text: '' } }]
            })
          });
        }
        const capitalizeStatus = status.charAt(0).toUpperCase() + status.toLowerCase().slice(1);
        this.notify('success', { message: this.$t(`Save${capitalizeStatus}Success`) });
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        this.notify('error', { message: this.$t(error?.message?.trim() || 'invalidData') });
      } finally {
        this.loading = false;
      }
    },
    saveCodeDraft() {
      const input = this.standardizedCode({
        status: 'DRAFT',
        title: this.title,
        category_id: this.categoryId,
        codes: this.codes.map(item => {
          if (!item.ssml?.text && !item.ssml?.is_enable) {
            delete item.ssml;
          }
          delete item.isPlaySpeech;
          delete item.contentOverLimit;
          delete item.preText;
          return item;
        })
      });
      this.createCode({
        input,
        notify: {
          type: 'success',
          message: this.$t('SaveDraftSuccess'),
          description: `${this.$t('Code name')}: ${this.title}`
        }
      });
    },
    saveCodePublish() {
      const input = this.standardizedCode({
        status: 'GENERATE',
        title: this.title,
        category_id: this.categoryId,
        codes: this.codes.map(item => {
          if (!item.ssml?.text && !item.ssml?.is_enable) {
            delete item.ssml;
          }
          delete item.isPlaySpeech;
          delete item.contentOverLimit;
          delete item.ssmlError;
          delete item.preText;
          return item;
        })
      });
      this.createCode({
        input,
        notify: {
          type: 'success',
          message: this.$t('SaveGenerateSuccess'),
          description: `${this.$t('Code name')}: ${this.title}`
        }
      });
    },
    async createCode({ input, notify }) {
      try {
        this.$ls.set('confirm-leave', true);
        this.loading = true;
        await this.$s.api.page.createCodes(this.pageId, input);
        this.notify(notify.type, {
          message: notify.message,
          description: notify.description
        });
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        this.notify('error', {
          message: this.$t(error?.message?.trim() || 'invalidData')
        });
      } finally {
        this.loading = false;
      }
    },
    notify(type = 'success', options = { message: '' }) {
      return new Promise(resolve => {
        setTimeout(() => {
          this.$notification[type](options);
        }, 100);
        resolve(1);
      });
    },
    previewQRCode() {
      const data = {
        code_type: 'voice_code',
        codes: this.codes?.map(item => {
          delete item.isPlaySpeech;
          delete item.contentOverLimit;
          delete item.ragt;
          return item;
        }),
        sym_size: this.size,
        sym_ec_level: this.ecLevel
      };
      this.$refs.previewQRRef.handlePreview(data);
    },
    cutStringByPatternAndLimit({ text, lang = 'eng' }) {
      const objMapping = {
        vie: { limit: 1000, pattern: '.' },
        jpn: { limit: 500, pattern: '。' },
        other: { limit: 1000, pattern: '.' }
      };
      const obj = objMapping[lang] || objMapping.other;
      let newText = text,
        listStr = [];
      while (newText) {
        const subStrByLimit = newText.substring(0, obj.limit);
        const lastIndexByPattern = subStrByLimit.lastIndexOf(obj.pattern);
        if (lastIndexByPattern != -1) {
          newText = newText.substring(lastIndexByPattern + 1, newText.length);
          listStr = [...listStr, subStrByLimit.substring(0, lastIndexByPattern + 1)];
        } else {
          newText = newText.substring(obj.limit, newText.length);
          listStr = [...listStr, subStrByLimit];
        }
      }
      return listStr;
    },
    checkContentOverLimit(code) {
      if (parseInt(this.size) == 0 || parseInt(this.size) == 1)
        // too small to cut
        return false;
      const MAX_LEN =
        (symSizeLength(this.size) - dbLength(this.linkURL)) / this.codes.length -
        subStringSpecialLangCase(code.lang, this.size);
      return dbLength(code.text) > MAX_LEN;
    },
    checkAndShowIfContentLimitCodes() {
      //check limit
      if (this.codes.length) {
        for (let i = 0; i < this.codes.length; i++) {
          this.codes[i].contentOverLimit = this.checkContentOverLimit(this.codes[i]);
        }
      }
    },
    openPreviewCodeContent({ id }) {
      const codeItem = this.codes.find(item => item.id === id);
      const MAX_LEN =
        (symSizeLength(this.size) - dbLength(this.linkURL)) / this.codes.length -
        subStringSpecialLangCase(codeItem.lang, this.size);

      this.contentPreview = buildCodeContent(codeItem.text, codeItem.lang, MAX_LEN);
      this.previewCodeContentVisible = true;
    },
    changeTags(tags) {
      this.tags = tags;
    }
  }
};
