//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoSleep from 'nosleep.js';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { LANGUAGE_OPTIONS } from '@/enum';
import PreviewQRCode from '@/components/spec/code-compose/child/manual/modals/PreviewQRCode';
import {
  dbLength,
  symSizeLength,
  buildCodeContent,
  subStringSpecialLangCase
} from '@/components/_util/encode';
import { PlainCodeItem, AdvancedInfo, ImportFile, BasicInfo, DictionaryInfo } from './components';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import { deepDiffMapper } from '@/utils/diff-objects';

export default {
  components: {
    PreviewQrcode: PreviewQRCode,
    PlainCodeItem,
    BasicInfo,
    AdvancedInfo,
    DictionaryInfo,
    ImportFile,
    PageHeader
  },
  data() {
    return {
      text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,
      customStyle:
        'background: white; border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',

      loading: false,
      title: '',
      size: '2',
      ecLevel: '1',
      linkURL: '',
      codes: [
        {
          id: 1,
          lang: 'jpn',
          title: '',
          text: '',
          ssml: {
            text: '',
            is_enable: false
          },
          ssmlError: {
            error: false,
            message: ''
          },
          isPlaySpeech: false,
          contentOverLimit: false
        }
      ],
      audio: null,
      noSleep: new NoSleep(),
      categoryId: null,
      contentPreview: '',
      previewCodeContentModal: false,
      thumbnail: {
        url: '',
        alt: ''
      },
      janCodes: [
        {
          id: 0,
          value: ''
        }
      ],
      tags: [],
      uploadLoading: false,
      activeTab: 'manual',
      createdDate: moment(),
      defaultCode: null,
      pollyEngine: 'standard',
      dictionary: [],
      isEditedFistCodeName: false
    };
  },
  computed: {
    ...mapGetters('page', ['pageById']),
    ...mapGetters('project', ['projectById']),

    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    page() {
      return this.pageById(this.pageId);
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    projectDictionary() {
      return this.currentProject?.dictionary || []
    },
    pageDictionary() {
      return this.page?.dictionary || []
    },
    deActiveButton() {
      return (
        this.codes.length < 1 ||
        !this.title ||
        (this.thumbnail?.url && !this.thumbnail?.alt) ||
        this.codes.some(({ text, lang, ssml }) => !text || !lang || (ssml?.is_enable && !ssml.text))
      );
    },
    validateTitle() {
      return this.activeTab === 'manual' && this.title === '' ? 'error' : 'success';
    },
    validateThumbnailALT() {
      const { url, alt } = this.thumbnail;
      return url && !alt ? 'error' : 'success';
    },

    enumLangs() {
      return LANGUAGE_OPTIONS;
    },
    usedLangs() {
      return this.codes.reduce((acc, item) => acc.concat(item.lang), []) || [];
    },
    noUseLangs() {
      return Object.keys(this.enumLangs).filter(langKey => !this.usedLangs?.includes(langKey));
    },
    code() {
      return {
        title: this.title,
        size: this.size,
        ecLevel: this.ecLevel,
        linkURL: this.linkURL,
        codes: this.codes,
        categoryId: this.categoryId,
        thumbnail: this.thumbnail,
        janCodes: this.janCodes,
        tags: this.tags,
        createdDate:
          typeof this.createdDate == 'string' ? this.createdDate : this.createdDate.toISOString()
      };
    },
    isDefaultDataChanged() {
      const result = deepDiffMapper.isDiff(this.defaultCode, this.code);
      return !this.deActiveButton && result;
    }
  },
  beforeRouteLeave(to, from, next) {
    const that = this;
    if (this.$ls.get('confirm-leave')) {
      this.$ls.remove('confirm-leave');
      return next();
    }
    if (to.name !== 'code-detail') {
      return this.$confirm({
        title: that.$t('Are you sure to leave this code detail?'),
        content: that.$t('The changed content will not be save.'),
        cancelText: that.$t('No, cancel'),
        okText: that.$t('Ok'),
        okType: 'primary',
        onOk() {
          next();
        },
        onCancel() { },
        class: 'test',
        width: 433
      });
    }
  },

  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
    }
    this.noSleep.disable();
  },
  async created() {
    this.getPageById({ projectId: this.projectId, pageId: this.pageId });
    const speechEngine = await this.$s.api.remoteConfig.getRemoteConfig('pollyEngine');
    if (speechEngine?.str_val) {
      this.pollyEngine = speechEngine.str_val;
    }
    this.defaultCode = JSON.parse(
      JSON.stringify({
        title: this.title,
        size: this.size,
        ecLevel: this.ecLevel,
        linkURL: this.linkURL,
        codes: this.codes,
        categoryId: this.categoryId,
        thumbnail: this.thumbnail,
        janCodes: this.janCodes,
        tags: this.tags,
        createdDate:
          typeof this.createdDate == 'string' ? this.createdDate : this.createdDate.toISOString()
      })
    );
  },
  methods: {
    ...mapActions('page', ['getPageById']),

    // Basic Info's Event
    changeTitle(value) {
      this.title = value;

      if (!this.isEditedFistCodeName || !this.codes[0].title) {
        this.codes[0].title = this.title
      }
    },
    changeCreatedDate(value) {
      this.createdDate = value;
    },
    changeCodeSize(value) {
      this.size = value;
    },
    changeErrorEcLevel(value) {
      this.ecLevel = value;
    },
    changeCategory(categoryId) {
      this.categoryId = categoryId;
    },

    changeThumbnail(newThumbnail) {
      this.thumbnail = { ...newThumbnail };
    },
    addCode() {
      const id = this.codes.length + 1;
      const newItem = {
        id,
        lang: this.noUseLangs[0],
        title: '',
        text: '',
        ssml: {
          text: '',
          is_enable: false
        },
        ssmlError: {
          error: false,
          message: ''
        },
        isPlaySpeech: false,
        contentOverLimit: false
      };
      this.codes = [...this.codes, newItem];

      // check limit content of all codes
      this.checkAndShowIfContentLimitCodes();
    },
    changeOriginalLink({ target }) {
      this.linkURL = target.value;
      // check limit content of all codes
      this.checkAndShowIfContentLimitCodes();
    },
    deleteCodeItem(id) {
      this.codes = [...this.codes.filter(item => item.id !== id)];
      // check limit content of all codes
      this.checkAndShowIfContentLimitCodes();
    },
    updateLang({ id, lang }) {
      this.codes = this.codes.map(item => {
        if (item.id !== id) {
          return item;
        }
        return { ...item, lang };
      });
    },
    updateTitle({ id, title }) {
      const codeIndex = this.codes.findIndex(item => item.id === id)
      if (!title) {
        this.isEditedFistCodeName = false
      } else if (codeIndex === 0 && !this.isEditedFistCodeName) { // Enable the isEditedFistCodeName flag
        this.isEditedFistCodeName = true
      }

      const codeItem = this.codes[codeIndex]
      codeItem.title = title;
    },
    updateContent({ id, content }) {
      const codeItem = this.codes.find(item => item.id === id);
      codeItem.text = content;
      codeItem.contentOverLimit = this.checkContentOverLimit(codeItem);
    },
    changeSSMLContent({ id, content }) {
      const codeItem = this.codes.find(item => item.id === id);
      const ssml = { ...codeItem.ssml, text: content };
      codeItem.ssml = ssml;
    },
    enableSSML({ id, is_enable }) {
      const codeItem = this.codes.find(item => item.id === id);
      const ssml = { ...codeItem.ssml, is_enable };
      codeItem.ssml = ssml;
    },
    changeSize(value) {
      this.size = value;
      // check limit content of all codes
      this.checkAndShowIfContentLimitCodes();
    },
    standardizedCode({ status, title, category_id, codes }) {
      this.createdDate = moment(this.createdDate).add(1, 'seconds');
      return {
        // commons fields
        created: this.createdDate,
        sym_size: this.size,
        sym_ec_level: this.ecLevel,
        linkURL: this.linkURL,
        thumbnail: this.thumbnail,
        jan_codes: this.janCodes.filter(item => item.value).map(item => item.value) || [],
        tags: this.tags,
        // specific fields
        status: status,
        title: title,
        category_id,
        codes: codes,
        dictionary: this.dictionary
      };
    },
    async saveCodesList({ status, codes }) {
      try {
        this.$ls.set('confirm-leave', true);
        this.loading = true;
        for (let i = 0; i < codes.length; i++) {
          const { title, categoryId, id, lang, text } = codes[i];
          await this.$s.api.page.createCodes(this.pageId, {
            ...this.standardizedCode({
              status,
              title,
              category_id: categoryId,
              codes: [{ id, lang, title: title, text }]
            })
          });
        }
        const capitalizeStatus = status.charAt(0).toUpperCase() + status.toLowerCase().slice(1);
        this.notify('success', { message: this.$t(`Save${capitalizeStatus}Success`) });
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        this.notify('error', { message: this.$t(error?.message?.trim() || 'invalidData') });
      } finally {
        this.loading = false;
      }
    },
    saveCodeDraft() {
      const input = this.standardizedCode({
        status: 'DRAFT',
        title: this.title,
        category_id: this.categoryId,
        codes: this.codes.map(item => {
          if (!item.ssml?.text && !item.ssml?.is_enable) {
            delete item.ssml;
          }
          delete item.isPlaySpeech;
          delete item.contentOverLimit;
          delete item.ssmlError;
          return item;
        })
      });
      this.createCode({
        input,
        notify: {
          type: 'success',
          message: this.$t('SaveDraftSuccess'),
          description: `${this.$t('Code name')}: ${this.title}`
        }
      });
    },
    saveCodePublish() {
      const input = this.standardizedCode({
        status: 'GENERATE',
        title: this.title,
        category_id: this.categoryId,
        codes: this.codes.map(item => {
          if (!item.ssml?.text && !item.ssml?.is_enable) {
            delete item.ssml;
          }
          delete item.isPlaySpeech;
          delete item.contentOverLimit;
          delete item.ssmlError;
          return item;
        })
      });
      this.createCode({
        input,
        notify: {
          type: 'success',
          message: this.$t('SaveGenerateSuccess'),
          description: `${this.$t('Code name')}: ${this.title}`
        }
      });
    },
    async createCode({ input, notify }) {
      try {
        this.$ls.set('confirm-leave', true);
        this.loading = true;
        // validate ssml limit content
        for (let i = 0; i < this.codes.length; i++) {
          let item = this.codes[i];
          // check valid ssml text
          if (item.ssml?.is_enable && item.ssmlError?.error) {
            throw new Error(item.ssmlError.message);
          }
        }
        await this.$s.api.page.createCodes(this.pageId, input);
        this.notify(notify.type, {
          message: notify.message,
          description: notify.description
        });
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        this.notify('error', {
          message: this.$t(error?.message?.trim() || 'invalidData')
        });
      } finally {
        this.loading = false;
      }
    },

    notify(type = 'success', options = { message: '' }) {
      return new Promise(resolve => {
        setTimeout(() => {
          this.$notification[type](options);
        }, 100);
        resolve(1);
      });
    },
    previewQRCode() {
      const data = {
        code_type: 'voice_code',
        codes: this.codes,
        sym_size: this.size,
        sym_ec_level: this.ecLevel
      };

      this.$refs.previewQRRef.handlePreview(data);
    },
    cutStringByPatternAndLimit({ text, lang = 'eng' }) {
      const objMapping = {
        vie: { limit: 1000, pattern: '.' },
        jpn: { limit: 500, pattern: '。' },
        other: { limit: 1000, pattern: '.' }
      };
      const obj = objMapping[lang] || objMapping.other;
      let newText = text,
        listStr = [];
      while (newText) {
        const subStrByLimit = newText.substring(0, obj.limit);
        const lastIndexByPattern = subStrByLimit.lastIndexOf(obj.pattern);
        if (lastIndexByPattern != -1) {
          newText = newText.substring(lastIndexByPattern + 1, newText.length);
          listStr = [...listStr, subStrByLimit.substring(0, lastIndexByPattern + 1)];
        } else {
          newText = newText.substring(obj.limit, newText.length);
          listStr = [...listStr, subStrByLimit];
        }
      }
      return listStr;
    },
    async getSynthesizeSpeech(text, lang, ssml, type, gender) {
      let listFuture = [],
        listStr = [],
        listControllers = [];
      if (type === 'ssml') {
        listStr = await this.$s.api.sound.cuttingSsmlIntoSentence(ssml.text);
      } else {
        let textContentMapping = {
          jpn: text
            .replace(/\n/gim, '。')
            .replace(/。\s{1,}/gim, '。')
            .replace(/。{2,}/gim, '。')
            .replace(/、{2,}/gim, '、')
            .replace(/\u3000{1,}/gim, '、')
            .replace(/、。/gim, '。')
            .replace(/。、/gim, '。'),
          other: text
        };
        let textContent = textContentMapping[lang] || textContentMapping.other;
        listStr = this.cutStringByPatternAndLimit({
          text: textContent,
          lang: lang
        });
      }
      const payload = {
        sentence: '',
        gender,
        language: lang,
        textType: type,
        engine: this.pollyEngine,
        options: {
          lexicon: this.currentProject?.lexicon?.length
            ? this.currentProject?.lexicon
            : this.page.lexicon,
          dictionary: (this.dictionary?.length > 0
            ? this.dictionary
            : this.pageDictionary?.length > 0
              ? this.pageDictionary
              : this.projectDictionary) || []
        }
      };
      listFuture = listStr.map(item => {
        const controller = new AbortController();
        listControllers.push(controller);
        return this.$s.api.sound.getSynthesizeSpeechStream(
          { ...payload, sentence: item },
          controller
        );
      });
      const data = await Promise.allSettled(listFuture);
      const bufferArray = data.reduce((arr, item) => {
        if (item.value) {
          arr = [...arr, ...item.value.mp3Stream.data];
        }
        return arr;
      }, []);
      const audioBlob = new Blob([new Uint8Array(bufferArray)], {
        type: 'audio/mp3'
      });
      const url = URL.createObjectURL(audioBlob);
      return url;
    },
    async handlePlaySpeech({ id, text, lang, ssml, type, gender }) {
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
      }
      try {
        this.loading = true;
        const url = await this.getSynthesizeSpeech(text, lang, ssml, type, gender);
        this.codes = this.codes?.map(item => {
          item.isPlaySpeech = item.id === id;
          return item;
        });
        this.audio = new Audio(url);
        this.audio.play();
        this.noSleep.enable();
        this.audio.onended = this.handleEndSpeech;
      } catch (error) {
        this.$notification.error({
          message: this.$t(error.TypeError) || this.$t('Network error')
        });
      } finally {
        this.loading = false;
      }
    },
    handlePauseSpeech({ id }) {
      this.codes = this.codes.map(item => {
        if (item.id === id) {
          item.isPlaySpeech = false;
        }
        return item;
      });
      this.audio.pause();
      this.audio = null;
      this.noSleep.disable();
    },
    handleEndSpeech() {
      this.codes = this.codes.map(item => {
        item.isPlaySpeech = false;
        return item;
      });
      this.noSleep.disable();
    },
    openPreviewCodeContent({ id }) {
      const codeItem = this.codes.find(item => item.id === id);
      const MAX_LEN =
        (symSizeLength(this.size) - dbLength(this.linkURL)) / this.codes.length -
        subStringSpecialLangCase(codeItem.lang, this.size);

      this.contentPreview = buildCodeContent(codeItem.text, codeItem.lang, MAX_LEN);
      this.previewCodeContentModal = true;
    },
    checkContentOverLimit(code) {
      if (parseInt(this.size) == 0 || parseInt(this.size) == 1)
        // too small to cut
        return false;
      const MAX_LEN =
        (symSizeLength(this.size) - dbLength(this.linkURL)) / this.codes.length -
        subStringSpecialLangCase(code.lang, this.size);
      return dbLength(code.text) > MAX_LEN;
    },
    checkAndShowIfContentLimitCodes() {
      //check limit
      if (this.codes.length) {
        for (let i = 0; i < this.codes.length; i++) {
          this.codes[i].contentOverLimit = this.checkContentOverLimit(this.codes[i]);
        }
      }
    },
    catchSSMLError(id, error) {
      const codeItem = this.codes.find(item => item.id === id);
      codeItem.ssmlError = error;
    },
    addJanCode() {
      const payload = {
        id: this.janCodes?.length || 0,
        value: ''
      };
      this.janCodes.push(payload);
    },
    removeJanCode(janCodesRemoved) {
      this.janCodes = this.janCodes.reduce((arr, item) => {
        if (item.id !== janCodesRemoved.id) arr.push(item);
        return arr;
      }, []);
    },
    changeTags(tags) {
      this.tags = tags;
    },
    changeDictionary(value) {
      this.dictionary = value
    },
  }
};
