var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.columns, function(col) {
            return _c(
              "th",
              {
                key: col.dataIndex,
                style: { width: col.width, "text-align": col.align || "start" }
              },
              [
                _vm._t(col.slots && col.slots.title, [
                  _vm._v(" " + _vm._s(col.title) + " ")
                ])
              ],
              2
            )
          }),
          0
        )
      ]),
      _c(
        "Draggable",
        {
          attrs: { tag: "tbody" },
          on: { change: _vm.handleDragEnd },
          model: {
            value: _vm.dataSource,
            callback: function($$v) {
              _vm.dataSource = $$v
            },
            expression: "dataSource"
          }
        },
        _vm._l(_vm.dataSource, function(item, index) {
          return _c(
            "tr",
            { key: item.id },
            _vm._l(_vm.columns, function(col) {
              return _c(
                "td",
                { key: col.key, style: { "text-align": col.align || "start" } },
                [
                  _vm._t(
                    col.scopedSlots.customRender,
                    [_vm._v(" " + _vm._s(item[col.dataIndex]) + " ")],
                    { text: item[col.dataIndex], record: item, index: index }
                  )
                ],
                2
              )
            }),
            0
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }