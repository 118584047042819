//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    columns: { type: Array, default: () => ([]) },
    dataList: { type: Array, default: () => ([]) },
  },
  data() {
    return {
      dataSource: []
    }
  },
  watch: {
    dataList: {
      handler() {
        this.dataSource = this.dataList || []
      },
      immediate: true
    }
  },
  methods: {
    handleDragEnd() {
      this.dataSource.forEach((item, index) => {
        item.order = index + 1;
      });
    }
  }
}
