//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapState } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapState('message', ['supportedLangs']),
    languages() {
      return this.params.data.localized_messages.map(item => this.$t(this.supportedLangs[item.lang]?.label));
    }
  }
});
