var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "flex-wrap": "wrap",
        gap: "4px 0",
        margin: "4px 0"
      }
    },
    _vm._l(_vm.languages, function(lang) {
      return _c("a-tag", { key: lang, attrs: { color: "blue" } }, [
        _vm._v(" " + _vm._s(lang) + " ")
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }